.page-loader {
  position: relative;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader{
    height: 31px;
    width: 31px;
  }
}


