.season-dropdown {
  background: #FFCF40 !important;
  padding: 5px 15px !important;
  font-size: 12px !important;
  margin-left: 50px;
}

.season-dropdown .menu {
  background: black !important;
  color: white !important;
  border: 1px solid white !important;
  border-radius: 0 !important;
  top: 130% !important;
}

.season-dropdown .menu > .item {
  color: white !important;
  &:hover {
    color: #c6a81c !important;
  }
}



