$grey-hard: rgba(72, 72, 72, 1);
$grey-medium: rgba(112, 112, 112, 1);
$grey-weak: rgba(177, 177, 177, 1);
$yellow-main: rgba(198, 168, 28, 1);
$bebas-neue: "Bebas Neue Bold", arial, serif;
$bebas-neue-light: "Bebas Neue Light", arial, serif;
$bebas-pro: "BebasPro",serif;

@font-face {
  font-family: "BebasPro";
  src: local("BebasPro"),
  url("assets/fonts/BebasNeuePro-Bold.ttf") format("truetype");
  font-weight: bold;
}

%border-setup {
  border: 1px solid rgba(177, 177, 177, 0.4);
  box-shadow: 3px 3px 3px rgba(177, 177, 177, 0.5),
  -3px 0 3px rgba(177, 177, 177, 0.25);
  border-radius: 5px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
}


