@import 'animate.css';

@charset "UTF-8";
/* CSS Document */

@font-face {
  font-family: '../fonts/sequel100black-65uploadedfile';
  src: url('../fonts/sequel100black-65-webfont.woff2') format('woff2'),
  url('../fonts/sequel100black-65-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Sequel100Black-65';
  src: url('../fonts/sequel100black-65-webfont.woff') format('woff2'),
  url('../fonts/sequel100black-65-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.highlightedArticle h4 {
  font-size: 200px;
}

.sequel {
  font-family: 'Sequel100Black-65', sans-serif;
}

.slick-slide img {
  min-width: calc(100% - 100px);
  /* margin: 0 50px;*/
}

.multiple-items img {
  text-align: center;
  min-width: calc(100% - 100px);
  margin: 0 50px;

}

@font-face {
  font-family: 'dharma_gothic_mexbold';
  src: url('../fonts/dharmagothicm-exbold-webfont.woff2') format('woff2'),
  url('../fonts/dharmagothicm-exbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  font-family: 'Montserrat', sans-serif;
  background: #000 url('../img/topBG.png') no-repeat;
  background-size: contain !important;
  overflow-x: hidden;
  line-height: 1.5;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: 0; /* Firefox */
}

body::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

h1, h2, h3, h4 {
  margin: 0px;
  padding: 0px;
  color: #01383f;
}

h1 {
  font-size: 56px;
  letter-spacing: -1px;
}

h2 {
  font-size: 19px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

h3 {
  font-size: 22px;
}

p {
  font-size: 19px;
  color: #384a4c;
}

a, button {
  transition: all 0.2s ease-in;
  outline: none !important;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.wrap {
  max-width: 1400px;
  width: 90%;
  margin: auto;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 0px;
  z-index: 99999;
}

header .logo {
  display: block;
  margin: auto;
  width: 150px;
}

header .wrap {
  position: relative;
}

header i.fa-bars {
  float: right;
  color: #fff;
  font-size: 30px;
  margin-top: -40px;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

header i.fa-bars:hover {
  color: #BF9B30;
}

.highlightedArticle h4 {
  font-size: 200px;
}

header .wrap {
  position: relative;
}

.navMenu {
  position: absolute;
  list-style: none;
  right: -14px;
  text-align: right;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 221px;
  padding-top: 57px;
  top: 14px;
  padding-right: 15px;
}

.navMenu li {
  margin-bottom: 8px;
}

.navMenu li a {
  color: #fff;
}

.navMenu li a:hover {
  color: #BF9B30;
}

.headerSpace {
  height: 150px;
}

.newsTitle {
  margin-bottom: 40px;
}

.newsTitle h1 {
  font-family: 'dharma_gothic_mexbold', sans-serif;
  color: #fff;
  font-size: 130px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  border-bottom: 4px solid #FFCF40;
  max-width: 600px;
  line-height: 127px;
  margin: auto;
}

.newsItem {
  margin: 0px 20px;
}

.newsItem img {
  width: 100%;
  height: 260px;
}

.newsItem h3 a {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

.newsItem h3 a:hover {
  color: #FFCF40;
}

.newsItem .category {
  background: #FFCF40;
  color: #000;
  text-transform: uppercase;
  font-family: 'Sequel100Black-65', sans-serif;
  font-weight: 300;
  font-size: 11px;
  padding: 5px 19px;
  display: inline-block;
  margin: 15px 0px;
}
.news-carousel-item {
  margin: 40px 0px;

}
.news-carousel-item .contents {
  display: inline-block;
  vertical-align: middle;
}
.img-inner {
  width: 525px;
  height: 240px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.img-inner img {
  position: absolute;
  width: 100%;
  height: 100%;

}
.news-carousel-item h3 a {
  color: #fff;
}

.news-carousel-item h3 a:hover {
  color: #FFCF40;
}

.news-carousel-item .category {
  background: #FFCF40;
  color: #000;
  text-transform: uppercase;
  font-family: 'Sequel100Black-65', sans-serif;
  font-weight: 300;
  font-size: 11px;
  padding: 5px 19px;
  display: inline-block;
  margin: 15px 0px;
  margin-top: 0;
}








.newsItemSmall {
  margin: 40px 0px;
}

.newsItemSmall img {
  width: 280px;
  height: 160px;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
}

.newsItemSmall .contents {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 290px);
  padding-left: 20px;
}

.newsItemSmall h3 a {
  color: #fff;
}

.newsItemSmall h3 a:hover {
  color: #FFCF40;
}

.newsItemSmall .category {
  background: #FFCF40;
  color: #000;
  text-transform: uppercase;
  font-family: 'Sequel100Black-65', sans-serif;
  font-weight: 300;
  font-size: 11px;
  padding: 5px 19px;
  display: inline-block;
  margin: 15px 0px;
  margin-top: 0;
  white-space: nowrap;
}

.newsSlider .fa-caret-circle-right {
  color: #ffd040;
  position: absolute;
  top: -24%;
  bottom: 0;
  margin: auto;
  font-size: 30px;
  height: 30px;
  right: -43px;
  cursor: pointer;
}

.newsSlider .fa-caret-circle-right:hover {
  opacity: 0.4;
}

.newsSlider .fa-caret-circle-left {
  color: #ffd040;
  position: absolute;
  top: -24%;
  bottom: 0;
  margin: auto;
  font-size: 30px;
  height: 30px;
  left: -43px;
  cursor: pointer;
}

.newsSlider .fa-caret-circle-left:hover {
  opacity: 0.4;
}

.announcement {
  background: #2F2F2F;
  padding: 90px 0px;
  margin: 100px 0px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.announcement.black {
  background: #000;
}

.announcement .sub {
  font-family: 'Sequel100Black-65', sans-serif;
  text-transform: uppercase;
  color: #FFCF40;
  font-size: 18px;
}

.announcement h3 {
  font-family: 'dharma_gothic_mexbold', sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 100px;
  line-height: 92px;
  margin-top: 25px;
}

.announcement h3:after {
  display: block;
  content: " ";
  height: 4px;
  width: 250px;
  background: #ffcf40;
  margin: auto;
  margin-top: 20px;
}

.announcement .top {
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
}

.announcement .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 700px;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  font-family: 'Sequel100Black-65', sans-serif;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  border-bottom: 3px solid #B2B2B2;
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding-left: 0;
  padding-right: 30px;
}

.nav-tabs .nav-item {
  margin-right: 10px;
}

.nav-tabs .nav-link.active {
  border-bottom: 3px solid #FFCF40;
  background: none;
  color: #fff;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.nav-tabs .nav-link:hover {
  border-bottom: 3px solid #FFCF40;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.loadMoreHidden {
  display: none;
}

.tweetSlider .slick-track {
  display: flex;
}

.tweetSlider .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.backToTop {
  font-family: 'Sequel100Black-65', sans-serif;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 15px;
  font-size: 12px;
  color: #000;
  background: #FFCF40;
  border: 1px solid #FFCF40;
  margin: 50px 0px;
  display: block;
  width: 200px;
  text-align: center;
}

.backToTop:hover {
  color: #ffcf40;
  background: none;
}

.backToTop.center {
  margin: 50px auto;
}

.newsSingleTitle {
  text-align: center;
  margin: 30px 0px;
}

.newsSingleTitle .wrap {
  max-width: 700px;
  margin: auto;
}

.newsSingleTitle .sub {
  text-transform: uppercase;
  color: #FFCF40;
  font-family: 'Sequel100Black-65', sans-serif;
  font-size: 18px;
}

.newsSingleTitle h1 {
  font-family: 'dharma_gothic_mexbold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 100px;
  line-height: 92px;
  margin-top: 15px;
}

.featuredImg {
  margin-bottom: 40px;
}

.featuredImg .wrap {
  position: relative;
  z-index: 9;
}

.featuredImg img.main {
  border-top: 4px solid #FFCF40;
  width: 100%;
}

.featuredImg .fill {
  background: #2F2F2F;
  padding: 200px 0px;
  text-align: center;
  position: relative;
  margin-top: -21%;
}

.featuredImg .fill .top {
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
}

.featuredImg .fill .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 700px;
}

.articleContents {
  color: #fff;
}

.articleContents p {
  color: #fff;
}

.articleContents .date {
  color: #fff;
  font-size: 12px;
  padding-bottom: 10px;
  max-width: 200px;
  border-bottom: 4px solid #FFCF40;
  margin-bottom: 50px;
  font-family: 'Sequel100Black-65';
}

.articleContents p strong {
  text-transform: uppercase;
  color: white;
  font-size: 28px;
}

.sidebar {
  padding-left: 30px;
  padding-top: 85px;
}

.sidebar .newsItem img {
  height: auto;
}

.sidebar .newsItem {
  margin-bottom: 50px;
}


.matchStats .vs {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  font-size: 115px;
  text-align: center;
  margin-left: 3px;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

.matchStats .vs span {
  background: #242424;
  width: 61px;
  display: inline-block;
  line-height: 100px;
  padding-top: 6px;
  margin: 0px 10px;
}

.matchStats .highlight {
  color: #FFCF40;
}

.matchStats .statItem {
  background: #242424;
  padding: 10px;
  text-align: center;
  color: #fff;
  font-size: 34px;
  font-family: 'Sequel100Black-65';
  text-transform: uppercase;
  margin-bottom: 15px;
}

.matchStats .statItem .label {
  color: #9b9b9b;
  font-size: 16px;
  margin-top: 14px;
}

.matchStats.results .meta {
  margin-bottom: 50px;
}

.matchStats .meta {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
  font-family: 'Sequel100Black-65';
  margin-bottom: 50px;
}

.matchStats .meta .right {
  text-align: center;
}

.matchStats .meta .tab {
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  margin-left: 15px;
  padding: 5px 9px;
  font-size: 12px;
  cursor: pointer;
}

.matchStats-tab {
  cursor: default !important;
}


.active-group {
  background: #fff;
  color: #000 !important;
}

.matchStats .meta .tab:first-child {
  margin-left: 0;
}

.matchStats .meta .tab:hover {
  background: #fff;
  color: #000;
}

.matchStats .meta .tab.highlight {
  color: #000;
  background: #FFCF40;
  border: 1px solid #FFCF40;
}

.matchStats .meta .tab.highlight:hover {
  color: #ffcf40;
  background: none;
}

.matchStats .meta .tab i {
  margin-right: 10px;
}

.matchStats .meta .col-12 {
  padding: 0;
  margin-top: 10px;
}

.newsSlider .slick-list {
  padding: 0 20% 0 0 !important;
}

.footer-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 1% 2%;
}

.footer-socials {
  display: flex;
  justify-content: end;
}

@media (max-width: 464px) {
  .hide-icons {
    display: none;
  }
  .fixture-tab {
    width: 80px !important;
  }
  .matchStats .meta .tab {
    font-size: 7px !important;
  }
  .matchStats .meta .tab:last-child {
    height: 53px;
    padding-top: 15px;
  }
  .matchStats .vs span {
    font-size: 80px !important;
    line-height: 60px !important;
    margin: 0 3px !important;
  }
  .matchStats .vs .name {
    font-size: 21px;
    line-height: 21px;
  }
  .matchStats .vs .score {
    transform: translateY(-55%) !important;
    padding-bottom: 40px !important;
  }
  footer .nav a {
    margin: 0 !important;
  }
}

@media(max-width: 600px) {
  .fixture {
    padding: 30px 10px 30px 30px !important
  }
  .fixture .matchRankFinal span {
    padding: 3px !important;
    font-size: 8px !important;
    width: 70px !important;

  }
  .matchRankFinal {
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 0 !important;
    justify-content: center !important;
  }
  .swiper-container {
    transform: translateY(30px);

    .swiper-button-prev {
      top: -25px !important;
      left: 50% !important;
      transform: translateX(-50px) !important;
    }

    .swiper-button-next {
      top: -25px !important;
      right: 50% !important;
      transform: translateX(50px) !important;
    }
  }

  .newsSlider .slick-list {
    padding: 0 0 0 0 !important;
  }
  .swiper .newsItemSmall img {
    height: 180px !important;
    width: 100% !important;
  }
  .rules {
    padding-top: 40px;
  }
  .announcement.about {
    margin-top: 40px !important;
  }
  .announcement.about2 .sub {
    margin-top: 40px;
  }
  .extraNews {
    padding-top: 40px;
  }
  .announcement {
    padding-top: 80px;
    padding-bottom: 0;
  }
  .newsItemSmall .category {
    background: #FFCF40;
    color: #000;
    text-transform: uppercase;
    font-family: 'Sequel100Black-65', sans-serif;
    font-weight: 300;
    font-size: 8px;
    padding: 5px 5px;
    display: inline-block;
    margin: 9px 0;
    margin-top: 0;
    white-space: nowrap;
  }
  .newsItemSmall {
    margin: 20px 0px;
  }
  .newsSlider .fa-caret-circle-right {
    right: -14px;
  }
  .newsSlider .fa-caret-circle-left {
    left: -14px;
  }
  .newsSlider {
    padding-bottom: 30px;
  }
  .navMenu li {
    font-size: 12px;
  }
  .matchStats .col-4 {
    padding-top: 2px;
  }
  .matchStats .statItem .label.long {
    margin-top: 2px;
  }
  .seriesSchedule .scheduleItem.highlight {
    background: #fff;
  }
  .newsTitle {
    border-bottom: 4px solid #FFCF40;
  }
  .currentTable .stat {
    font-size: 12px;
    padding-top: 12px;
    width: 94%;
  }
  .currentTable .points {
    font-size: 27px;
    padding-top: 6px;
  }
  .currentTable .row > * {
    padding-left: 0px;
    padding-right: 0px;
  }
  .currentTable .name {
    line-height: 16px;
    font-size: 16px;
    padding-top: 7px;
    background-color: #242424;
  }
  .currentTable .name {
    margin-right: 3px;
  }
  .currentTable .stat.white {
    font-size: 16px;
  }
  #homeHero {
    margin-bottom: -310px;
  }
  .newsTitle.home h1 {
    width: 300px;
    position: relative;
    padding-bottom: 10px;
  }
  .homeNav a {
    font-size: 12px;
  }
  footer .nav a {
    width: 100%;
    margin: 20px 0px;
  }
  .matchStats .name {
    font-size: 32px !important;
    text-align: left;
    line-height: 32px !important;
  }
  .matchStats .statItem {
    text-align: left;
  }
  .matchStats .statItem .label {
    text-align: center;
  }
  .matchStats .statItem .right {
    text-align: right;
  }
  .matchStats .vs span {
    line-height: 45px;
    font-size: 41px;
    width: 38px;
    margin: 3px;
  }

  .fixtureBlock .subTitle {
    text-transform: uppercase;
    color: #B2B2B2;
    text-align: center;
    padding-bottom: 17px !important;
  }
  .fixture {
    border: 1px solid #fff;
    position: relative;
    padding-left: 0;
    padding: 30px 10px 10px 10px;
    cursor: pointer;
    position: relative;
  }
  .fixture:hover {
    border: 1px solid #FFCF40;
  }
  .fixture:hover .title {
    background: #FFCF40;
  }
  .fixture .item {
    font-size: 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 10px;
  }

  .fixtureUpcoming h4 {
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fixture .item .score {
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
    padding-bottom: 15px;
  }
  .fixture .title {
    width: 135px !important;
  }

  .fixture .item .name {
    font-size: 35px !important;
  }
  .fixtureUpcoming {
    position: relative;
    margin-top: 30px;
  }
  .fixtureUpcoming .time {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -19px;
  }
  .fixtureUpcoming .names {
    display: flex;
    justify-content: space-between;
    padding: 0%;
    font-size: 32px;
  }

  .fixtureUpcoming h4 {
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .announcement .vidEmbed {
    margin-bottom: -30px;
  }
  .history p.intro {
    text-align: left;
    margin: 20px 0;
    font-size: 14px;
  }
  .history h3 {
    font-size: 63px;
    line-height: 60px;
  }
  .honours .item img, .honours .item .contents .board {
    height: 240px;
  }
  footer .nav a {
    display: block;
    width: 100% !important;
  }
  .matchStats .meta .tab {
    font-size: 7px;
    width: 92px;
    margin-left: -1px;
  }
  .matchStats .vs {
    line-height: 36px;
  }
  .matchStats .vs .name {
    padding-top: 15px;
  }
  .newsSingleTitle h1 {
    font-family: 'dharma_gothic_mexbold';
    color: #fff;
    text-transform: uppercase;
    font-size: 70px;
    line-height: 67px;
    margin-top: 15px;
  }

  .announcement {
    margin: 0;
  }
  .announcement.home {
    padding: 60px 0px;
  }
  .honours-other-players .dropdown-players-item {
    font-size: 24px !important;
  }
  .honours .item .top .name {
    font-size: 40px !important;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-menu {
    margin-top: 3%;
  }
  .footer-socials {
    margin-top: 3%;
    justify-content: center !important;
  }
}

.newsSliderMob {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  padding-bottom: 36px;
}

.newsSliderMob .wrap {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.newsSliderMob h3:after {
  display: none;
}

.newsSliderMob .newsItem {
  border: 2px solid #ffcf40;
  margin: 0;
}

.newsSliderMob .newsItem .body .body {
  padding: 20px;
  padding-top: 0;
}

.newsSliderMob .newsItem h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px !important;
  text-transform: none;
  line-height: 26px;
}

.newsSliderMob .newsItem.slick-current {
  border: 2px solid #fff;
  transform: scale(1.1);
}

.newsSliderMob .slick-list, .newsSliderMob .slick-track {
  overflow: visible;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  position: relative;
  top: 30px;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #B2B2B2;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #ffcf40;
}

.seriesSchedule {
  background: #2F2F2F;
  padding: 20px 0px;
  margin: 100px 0px;
  text-align: center;
  position: relative;
  margin-bottom: 0px;
}

.seriesSchedule .sub {
  text-transform: uppercase;
  color: #FFCF40;
  font-size: 18px;
  font-family: 'Sequel100Black-65';
}

.seriesSchedule h3 {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  font-size: 100px;
  line-height: 92px;
  margin-top: 25px;
}

.seriesSchedule h3:after {
  display: block;
  content: " ";
  height: 4px;
  width: 100%;
  max-width: 950px;
  background: #ffcf40;
  margin: auto;
  margin-top: 20px;
}

.seriesSchedule .top {
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
}

.seriesSchedule .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 700px;
}

.seriesSchedule .scheduleItem {
  text-align: center;
  padding: 20px 20px;
  position: relative;
  font-family: 'dharma_gothic_mexbold', sans-serif;
  font-size: 40px;;
  margin-top: 20px;
  color: white;
}

.honours .item {
  border: 1px solid #fff;
}

.honours .item .top {
  border-bottom: 1px solid #fff;
}

.honours .item .top .position {
  display: inline-block;
  width: 80px;
  color: #000;
  font-size: 60px;
  background: #FFCF40;
  font-family: 'dharma_gothic_mexbold', sans-serif;
  text-transform: uppercase;
  text-align: center;
  height: 90px;
}

.honours .item .top .name {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  display: inline-block;
  width: calc(100% - 100px);
  padding-left: 23px;
}

.honours .item .top .name i {
  float: right;
  font-size: 40px;
  position: relative;
  top: 25px;
  cursor: pointer;
  right: 10px;
}

.honours .item .top .name i:hover {
  color: #FFCF40;
}

.honours .item .top .name i.active {
  transform: rotate(180deg);
}

.honours .item img {
  width: 100%;
  height: 320px;
}

.honours .item .contents {
  position: relative;
}

.honours .item .contents .board {
  position: absolute;
  top: 0;
  width: 100%;
  background: #000;
  height: 320px;
  overflow: auto;
  display: none;
}


.honours .item .contents .board .position:nth-child(odd) {
  background: #2F2F32;
}

.honours .item .contents .board .position .number small {
  font-size: 10px;
}

.honours .item .contents {
  position: relative;
  overflow: hidden;
}

.honours .item .date {
  color: #989898;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 17px;
  top: 12px;
  font-family: 'Sequel100Black-65';
}

.newsTitle.home h1 {
  max-width: 800px;
}

.newsTitle.home .sub {
  font-family: 'Sequel100Black-65';
  text-transform: uppercase;
  color: #FFCF40;
  font-size: 28px;
  text-align: center;
}

#homeHero {
  margin-bottom: -140px;
}

#homeHero img {
  width: 100%;
}

.highlightedArticle {
  padding-top: 150px;

}

.highlightedArticle-pad {
  padding: 50px 50px;
}

.highlightedArticle .subTitle {
  text-transform: uppercase;
  font-family: 'Sequel100Black-65';
  color: #FFCF40;
  font-size: 14px;
}

.highlightedArticle h4 {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  font-size: 91px;
  line-height: 80px;
  margin: 10px 0;
  margin-bottom: 18px;
}

.highlightedArticle a {
  color: #000;
  background: #FFCF40;
  font-family: 'Sequel100Black-65';
  text-transform: uppercase;
  padding: 8px 20px;
  font-size: 10px;
  text-align: left;
}

.highlightedArticle img {
  max-width: 100%;
}

.announcement .vidEmbed {
  max-width: 950px;
  margin: auto;
  margin-bottom: 50px;
  margin-top: -60px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fixtureBlock .subTitle {
  text-transform: uppercase;
  color: #B2B2B2;
  font-size: 14px;
}

.fixture {
  padding: 19px 13px;
  border: 1px solid #fff;
  margin: 15px 0px;
  position: relative;
  padding-left: 39px;
  cursor: pointer;
  line-height: 1.5;
}

.fixture:hover {
  border: 1px solid #FFCF40;
}

.fixture:hover .title {
  background: #FFCF40;
}

.fixture .title {
  position: absolute;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  transform: rotate(270deg);
  width: 135px;
  text-align: center;
  padding: 3px;
  font-family: 'Sequel100Black-65';
  left: 0;
  top: 0;
  transform-origin: left top;
  transform: rotate(-90deg) translate(-100%, 0);
}

.fixture .item {
  margin: -17px 0px;
}

.fixture .item .score {
  color: #000;
  font-size: 22px;
  background: #FFCF40;
  width: 41px;
  padding: 2px 0px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-family: 'Sequel100Black-65', sans-serif;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-top: 3px;
}

.fixture.pending .item .score {
  background: #B2B2B2;
}

.fixture .item .name {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  font-size: 49px;
  letter-spacing: 0.2px;
  display: inline-block;
  vertical-align: middle;
}

.fixtureUpcoming {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  padding: 10px;
  margin: 15px 0px;
}

.fixtureUpcoming:hover {
  cursor: pointer;
  border: 1px solid #FFCF40;
}

.fixtureUpcoming:hover .time {
  background: #fff;
}

.fixtureUpcoming .time {
  color: #000;
  background: #FFCF40;
  padding: 5px;
  text-align: center;
  min-width: 75px;
  max-width: 110px;
  font-size: 11px;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  position: relative;
}

.fixtureUpcoming .names {
  display: inline-block;
  vertical-align: middle;
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 38px;
  line-height: 39px;
  padding-left: 18px;
  padding-top: 2px;
}

.fixtureUpcoming .names small {
  font-size: 70%;
  display: inline-block;
  margin: 0px 10px;
}

.currentTable {
  max-width: 800px;
  margin: auto;
  margin-top: 60px;
}

.currentTable .stat, .currentTable .name, .currentTable .points {
  background: #242424;
  color: grey;
  min-height: 55px;
}

.currentTable .col-1 {
  flex: 1 0 8.333333%;
  max-width: 10.333333%;
}

.currentTable .stat {
  padding-top: 15px;
  font-family: 'Sequel100Black-65', sans-serif;
}

.currentTable .stat.white {
  color: #fff;
}

.currentTable .row > * {
  padding-left: 10px;
  padding-right: 10px;
}

.currentTable .name {
  text-align: left;
  font-family: 'dharma_gothic_mexbold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 35px;
  padding: 0px 17px;
  padding-top: 2px;
}

.currentTable .points {
  font-family: 'dharma_gothic_mexbold';
  font-size: 35px;
  color: #FFCF40;
}

.rules {
  background: #2F2F2F;
  padding: 90px 0px;
  position: relative;
}

.rules .top {
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
}

.rules .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 700px;
}

.rules .subTitle {
  text-transform: uppercase;
  color: grey;
  border-bottom: 4px solid #FFCF40;
  width: 300px;
  font-family: 'Sequel100Black-65';
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.rules p {
  font-family: 'Montserrat', sans-serif;
  color: #fff !important;
  font-size: 17px;
}

.rules p strong {
  font-family: 'Sequel100Black-65', sans-serif;
  text-transform: uppercase;
  display: block;
  margin: 30px 0px;
  color: white;
  text-align: left;
  line-height: 30px;
  font-weight: normal;
}

.rules .mainContent {
  padding-right: 80px;
}

.rules .rule {
  background: #000;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 20px 28px;
  margin: 40px 0px;
  padding-bottom: 30px;
  font-family: 'Sequel100Black-65';
  font-size: 14px;
  text-transform: uppercase;
  min-height: 162px;
}


.rules .rule .number {
  background: #FFCF40;
  color: #000;
  font-family: 'Sequel100Black-65';
  width: 56px;
  text-align: center;
  padding-top: 2px;
  font-size: 23px;
  display: block;
  margin: auto;
  margin-top: -39px;
  margin-bottom: 26px;
  font-weight: 600;

  .rules-subtitle {
    font-weight: bold;
    color: white !important;
  }
}

.history {
  padding: 50px 0px;
}

.history .sub {
  text-transform: uppercase;
  color: #FFCF40;
  font-size: 18px;
  text-align: center;
  margin-bottom: -13px;
  font-family: 'Sequel100Black-65';
}

.history h3 {
  font-family: 'dharma_gothic_mexbold';
  color: #fff;
  text-transform: uppercase;
  font-size: 100px;
  line-height: 92px;
  margin-top: 25px;
  text-align: center;
}

.history h3:after {
  display: block;
  content: " ";
  height: 4px;
  width: 100%;
  background: #ffcf40;
  margin: auto;
  margin-top: 20px;
}

.history p.intro {
  font-weight: bold;
  margin: 60px 140px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.history p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 20px;
}

.history .cta {
  color: #000;
  background: #FFCF40;
  text-transform: uppercase;
  padding: 10px 35px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Sequel100Black-65';
  margin-top: 30px;
}

.history .cta:hover {
  background: #bd9827;
}

footer {
  border-top: 4px solid #FFCF40;
  padding-top: 0.5%;
}

footer .logo {
  width: 120px;
}

footer .nav {
  text-align: center;
}

footer .nav a {
  color: #fff;
  display: inline-block;
  margin: 20px 19px;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
}

footer .nav a:hover, footer .socials a:hover {
  color: #FFCF40;
}

footer .socials a {
  color: #fff;
  font-size: 23px;
  display: inline-block;
  margin: 2% 3px;
}

footer .nav a {
  line-height: 25px;
}

footer .igameLogo {
  width: 64px;
  position: relative;
  top: -3px;
}

footer .twitter {
  width: 24px;
  position: relative;
  top: -3px;
}

.homeNav {
  text-align: center;
}

.homeNav a {
  color: #fff;
  border: 1px solid #FFCF40;
  width: 250px;
  margin: 0px 4px;
  padding: 15px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: -27px;
  margin-bottom: 56px;
  letter-spacing: 0.4px;
}

.homeNav a:hover {
  color: #000;
  background: #FFCF40;
}

@media(max-width: 1490px) {
  .swiper .newsItemSmall img {
    width: 450px !important;
  }
  .footer-wrapper {
    padding: 1% 1% 0 2%;
  }
  .footer-menu {
    display: flex;
    justify-content: end;
  }
  .results-name {
    font-size: 70px !important;
    line-height: 70px !important;
  }
  .rov .vs .score {
    max-width: 20% !important;
  }
}

@media(max-width: 1100px) {

  .newsSingleTitle h1 {
    letter-spacing: 0.2px;
  }
}

@media(max-width: 1299px) {
  .highlightedArticle {
    padding-top: 50px;
  }
  .matchStats .meta .right {
    text-align: center;
  }
  .season-tabs {
    margin-bottom: 20px;
  }
  .md-back-refresh-container {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .tab .highlight {
      width: 1000px;
    }
  }
  footer .nav a {
    margin: 20px 1%;
  }
  .matchStats .vs span {
    width: 40px;
  }
}


@media(max-width: 1222px) {
  .wrap {
    width: 95%;
  }
  footer .nav a {
    margin: 20px 1.5%;
    line-height: 0;
  }
  .swiper .newsItemSmall img {
    width: 350px !important;
  }

}

@media(max-width: 1020px) {
  .swiper .newsItemSmall img {
    width: 380px !important;
  }
  .newsTitle.about span {
    display: block;
  }
  .seriesSchedule .hideMobile {
    display: none;
  }
  .newsTitle h1 {
    font-size: 87px;
    line-height: 85px;
  }
  .newsTitle.home .sub {
    font-size: 20px;
    margin-bottom: -15px;
  }
  .announcement h3 {
    font-family: 'dharma_gothic_mexbold';
    font-size: 80px;
    line-height: 75px;
    margin-top: 15px;
  }
  .fixtureBlock {
    padding: 0px 30px;
  }
  .highlightedArticle-pad {
    padding: 50px 50px;
  }
  footer {
    text-align: center;
  }
  footer .nav {
    display: inline-block;
  }
  .announcement h3 br {
    display: none;
  }
  .rules .mainContent {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .history p.intro {
    margin: 60px 40px;
  }
  .matchStats .vs span {
    margin: 0 3px;
  }
  .matchStats .statItem {
    font-size: 24px;
  }
  .matchStats .statItem .label {
    font-size: 11px;
    margin-top: 9px;
  }

  .featuredImg .fill {
    display: none;
  }
  .articleContents p {
    font-size: 16px;
  }
  .sidebar {
    padding: 0;
    margin-top: 40px;
  }
  .honours .item {
    margin-bottom: 40px;
  }
  .newsTitle.home h1 {
    margin-top: 20px;
  }
  footer .nav a {
    width: 110%;
    margin: 0.5% 0;
  }
  footer .footer-logo {
    width: 30%;
  }
  .footer-wrapper {
    padding: 1% 2%;
  }
  .footer-socials {
    justify-content: end;
  }
}

.tweetSlider.hideDesktop .twitter-tweet {
  margin: auto;
}

.hideDesktop, .hideDesktopInline {
  display: none;
}

.hideDesktop2 {
  display: none;
}

.tweetItem {
  padding: 30px;
  width: 33.33%;
}

.tweetSlider {
  background: #2F2F2F;
  margin-top: 70px;
  padding: 40px 10% 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media(max-width: 900px) {
  .results-name {
    line-height: 25px !important;
    font-size: 60px !important;
  }
  .mobile-seasons-tabs {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .mobile-weeks-tabs {
    .season-dropdown .menu > .item {
      font-size: 12px;
    }
  }
  .season-dropdown {
    margin-left: 0 !important;
    padding: 5px 7px !important;
  }
  .video-container {
    display: flex !important;
    justify-content: center !important;
  }
  .video-wrapper {
    width: 70% !important;
    height: 265px !important;
  }
  .swiper-button-next {
    right: 10px !important;
  }
  .swiper-button-prev {
    left: 10px !important;
  }
  .swiper .newsItemSmall {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .swiper .newsItemSmall img {
    width: 65% !important;
  }
  .newsSingleTitle .wrap {
    max-width: 1400px;
  }
  .newsSliderMob {
    margin-top: 0;
  }
  .history .cta {
    margin: auto;
    display: block;
    width: 268px;
    text-align: center;
    margin-top: 30px;
  }
  .homeNav a {
    padding: 11px;
    width: 204px;
    font-size: 12px;
  }
  .backToTop {
    margin: 50px auto;
  }
  .highlightedArticle h4 {
    font-size: 50px !important;
    line-height: 45px;
  }
  .newsTitle h1 {
    position: Relative;
  }
  .newsSliderMob .newsItem .category {
    background: #FFCF40;
    color: #000;
    text-transform: uppercase;
    font-family: 'Sequel100Black-65';
    font-weight: 300;
    font-size: 10px;
    padding: 5px 14px;
    display: inline-block;
    margin: 15px 0px;
    margin-bottom: 5px;
  }
  .honours {
    padding-bottom: 45px;
  }
  .newsSingleTitle {
    background: #2F2F2F;
    padding: 40px 0px;
    text-align: center;
    margin-top: 0;
    position: relative;
  }
  .newsSingleTitle p {
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
    border-top: 2px solid #FFCF40;
    margin-top: 23px;
    padding-top: 22px;
    margin-bottom: 0;
  }
  .newsSingleTitle .date {
    margin-bottom: 10px;
    font-family: 'Sequel100Black-65';
    font-size: 12px;
  }
  .newsSingleTitle .top {
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;
  }
  .newsSingleTitle .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 700px;
  }
  .matchStats.hideDesktop {
    margin: 15px;
    margin-top: 60px;
    margin-bottom: -20px;
  }
  .matchStats.results .meta {
    margin-bottom: 0;
  }
  .currentTable .name {
    font-size: 22px;
  }
  .newsSingleTitle .date {
    color: #fff;
    margin-bottom: 10px;
  }
  .hideDesktop {
    display: block;
  }
  .hideDesktopInline {
    display: inline;
  }
  .matchStats .vs {
    margin-left: -5px;
    margin-right: -5px;
  }
  .hideMobile {
    display: none !important;
  }
  .seriesSchedule {
    margin-top: 0;
  }
  .seriesSchedule .scheduleItem {
    width: 88%;
    margin: auto;
  }
  .seriesSchedule .count {
    background: #FFCF40;
    width: 40px;
    height: 40px;
    color: #000;
    font-family: 'Sequel100Black-65';
    font-size: 16px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    padding-top: 9px;
    position: absolute;
    top: 0;
    left: -18px;
    right: auto;
    margin: auto;
    font-weight: bold;
    bottom: 0;
  }
  .announcement h3 {
    font-size: 80px;
    line-height: 70px;
  }
  .announcement.home h3 {
    font-size: 80px;
    line-height: 70px;
  }
  .history img {
    margin-top: 50px;
  }
  .rules .col-xl-6.wow {
    width: 50%;
  }
  .rules {
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background: #000;
  }
  .rules p, .history p {
    font-size: 14px;
  }
  .rules .rule .number {
    font-weight: 400;
    width: 75px;
  }
  .rules .rule {
    background: #000;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 20px 14px;
    margin: 25px 0px;
    padding-bottom: 21px;
    font-size: 12px;
    border: 1px solid #909090;
    min-height: 125px;
  }
  .rules .rule br {
    display: none;
  }
  .mobileDate {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 65px;
    display: block;
    margin-bottom: 9px;
  }
  .mobileDate span {
    color: #FFCF40;
  }
  .matchStats .meta .tab {
    font-size: 8px;
    margin-left: 6px;
    text-align: center !important;
    width: 100px;
    display: inline-block;
  }
  .matchStats .vs span {
    margin: 0 1px;
  }
}

@media(max-width: 750px) {
  .announcement.home {
    margin-top: 0;
    padding: 30px 0px;
  }
  .announcement .sub {
    font-size: 13px;
  }
  .announcement h3, .announcement.home h3 {
    font-size: 50px !important;
    line-height: 45px;
  }
  .fixtureBlock .subTitle {
    padding-top: 30px;
  }
  .homeNav {
    width: 100%;
    margin: auto;
    margin-top: -20px;
  }
  .homeNav a.first {
    width: 97%;
  }
  .homeNav a {
    width: 47%;
    margin: 1%;
    margin-bottom: 12px;
    border: 1.5px solid #FFCF40;
  }
  .matchStats .vs {
    font-size: 38px;
  }
  .matchStats .vs span {
    line-height: 45px;
  }
  .seriesSchedule h3 {
    font-family: 'dharma_gothic_mexbold';
    color: #fff;
    text-transform: uppercase;
    font-size: 80px;
    line-height: 73px;
    margin-top: 25px;
  }
  .articleContents p {
    font-size: 14px;
  }
  .matchStats .name {
    font-size: 40px !important;
    text-align: left;
    line-height: 18px !important;
  }
  .matchStats .name.right {
    text-align: initial;
  }
  .matchStats .vs span {
    line-height: 63px;
    font-size: 70px;
  }
  .matchStats .vs .score {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  footer .nav {
    padding: 0;
  }
  .honours .item .top .name {
    font-size: 48px;
    width: calc(100% - 73px);
    padding-left: 15px;
  }
  .honours .item .top .position {
    width: 60px;
  }
  .honours .item .top .name i {
    float: right;
    font-size: 31px;
    position: relative;
    cursor: pointer;
    right: 7px;
    top: 50%;
    transform: translateY(50%);
  }
  .newsTitle.home h1 {
    line-height: 73px;
  }
  .newsTitle {
    border-bottom: 0 !important;
  }
  .highlightedArticle h4 {
    font-size: 50px !important;
    line-height: 45px;
  }
  .nav-tabs .nav-link {
    font-size: 9px;
    padding-right: 10px;
  }
  .nav-tabs .nav-item {
    margin-right: 5px;
  }
  .newsItemSmall img {
    width: 165px;
    height: 130px;
  }
  .newsItemSmall .contents {
    width: calc(100% - 190px);
  }
  .newsItemSmall h3 {
    font-size: 17px;
  }
  .matchStats.hideDesktop {
    padding-bottom: 5px;
  }
  .announcement.tableItem {
    padding: 40px 0px;
    margin-top: 40px;
  }
}

@media(max-width: 680px) {

  header .logo {
    width: 120px;
  }
  .hideMobile2 {
    display: none !important;
  }
  .hideDesktop2 {
    display: block !important;
  }
}

@media (max-width: 769px) {
  .wrap {
    width: 90%;
  }
  .footer {
    padding-top: 20px;
  }
  .contact-inner {
    flex-direction: column;
  }
  .footer-title {
    text-align: center;
  }
  .social-block {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .matchStats .statItem {
    padding: 10px 3px;
  }
  .navMenu {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
  }
  footer .nav a {
    width: 120%;
  }
}

@media (max-width: 450px) {
  .fixture .item .name {
    font-size: 27px !important;
  }
  .fixture .title {
    width: 130px !important;
  }
  .fixture .item .score {
    top: 5px;
    margin-top: 10px;
  }
}

@media (max-width: 1025px) {
  .twitter-slider {
    flex-direction: column;
    align-items: center;
  }
  .tweetItem {
    width: 85% !important;
    padding: 0 !important;
  }
}

.tab-pane {
  background: black !important;
}

.swiper-container {
  position: relative;
}

.swiper {
  width: 100%;
  height: 370px;

  .col-xl-6 {
    max-width: 100%;
  }

  .newsItemSmall {
    margin: 0;

    .contents {
      width: initial;
      padding: 0;
    }

    .category {
      margin-top: 7px !important;
    }
  }

  .newsItemSmall img {
    width: 520px;
    height: 260px;
    transform: translateY(-8px);
  }

}

.swiper-slide {
  color: white;
}

.swiper-button-prev {
  left: -5% !important;
  top: 40% !important;
  width: 35px !important;
  height: 35px !important;
  background: black !important;
  border: 4px solid #ffcf40 !important;
  border-radius: 100% !important;

  &:hover {
    border-color: #66531a !important;

    &:after {
      border-right: 10px solid #66531a !important;
    }
  }

  &:after {
    content: '' !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 8px solid transparent !important;
    border-right: 10px solid #ffcf40 !important;
    border-bottom: 8px solid transparent !important;
    position: absolute !important;
    left: 25% !important;
  }
}

.swiper-button-next {
  right: -5% !important;
  top: 40% !important;
  width: 35px !important;
  height: 35px !important;
  background: black !important;
  border: 4px solid #ffcf40 !important;
  border-radius: 100% !important;

  &:hover {
    border-color: #66531a !important;

    &:after {
      border-left: 10px solid #66531a !important;
    }
  }

  &:after {
    content: '' !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 8px solid transparent !important;
    border-left: 10px solid #ffcf40 !important;
    border-bottom: 8px solid transparent !important;
    position: absolute !important;
    right: 25% !important;
  }
}


.twitter-slider {
  display: flex;
}

.viewMoreBtn {
  height: 35px;
}

.hideMoreBtn {
  display: none;
}

.results-name {
  line-height: 77px;
  font-size: 77px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-right {
    align-items: flex-end;

  }
}

.dropdown-players-show {
  width: 100%;
  height: 320px;
  position: absolute;
  top: 0;
  animation: moveDown 0.5s;
  background-color: transparent;
}

.dropdown-players-hidden {
  top: 100%;
  height: 320px;
  width: 100%;
  animation: moveUp 0.5s;
  transition: 15s;
  position: absolute;
}

.honours-other-players {
  padding: 10px 20px;
  height: 60px;
  background-color: #2F2F32;
  display: flex;
  align-items: center;

  .number {
    display: inline-block;
    color: #000;
    background: #FFCF40;
    width: 40px;
    height: 40px;
    font-size: 13px;
    text-align: center;
    padding-top: 11px;
    border-radius: 50%;
    margin-right: 30px;
    text-transform: uppercase;
    vertical-align: middle;
    font-family: 'Sequel100Black-65';
  }

  .dropdown-players-item {
    font-family: 'dharma_gothic_mexbold';
    width: 100%;
    display: flex;
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    letter-spacing: 1px;
    vertical-align: middle;
    align-items: center;
  }
}

.video-wrapper {
  width: 760px;
  height: 415px;
}

.noExistingNews {
  font-family: "Sequel100Black-65", sans-serif;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 70px 0 30px 0;
  color: #FFCF40;
  font-size: 18px;
}

.winner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 40px 0 40px 0;
  width: 100%;
  height: 200px;
  text-align: center;
  background: url(../img/winner-bg.png) center;
  background-size: cover;
}

.winnerName {
  font-family: 'dharma_gothic_mexbold', sans-serif;
  font-size: 80px;
  line-height: 100px;
}

.winnerTitle {
  font-family: 'Sequel100Black-65', sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.fixture .matchRank, .matchRankQf, .matchRankSf, .matchRankFinal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fixture .matchRank span,
.fixture .matchRankQf span,
.fixture .matchRankSf span,
.fixture .matchRankFinal span {
  font-family: 'Sequel100Black-65';
  font-size: 10px;
  padding: 5px;
  width: 80px;
  margin-bottom: 5px;
  text-align: center;
}

.fixture .matchRank span {
  background-color: #989898;
}

.fixture .matchRankQf span {
  background-color: #C47100;
}

.fixture .matchRankSf span {
  background-color: #DADADA;
}

.fixture .matchRankFinal span {
  background-color: #ffcf40;
}

.fixture .matchRank, .matchRankQf, .matchRankSf, .matchRankFinal {
  position: absolute;
  top: 40%;
  right: -0.2%;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture .matchRank span,
.fixture .matchRankQf span,
.fixture .matchRankSf span,
.fixture .matchRankFinal span {
  font-family: 'Sequel100Black-65';
  font-size: 11px;
  padding: 5px;
  margin-bottom: 5px;
  text-align: center;
}

@keyframes moveDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes moveUp {
  0% {
    top: 0%;
  }
  100% {
    top: -100%;
  }
}

.blank {
  min-height: 100px;
}
