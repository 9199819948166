@media (max-width: 414px) {
  .dropdown-wrapper-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .matchStats .meta .col-12 {
    margin-top: 20px;
  }
  .matchStats.hideDesktop {
    margin: 0 !important;
    .ui.dropdown {
      padding: 4px 10px !important;
      font-size: 9px !important;
    }
  }
  .col-8 {
    margin-top: 10px !important;
  }
  .newsTitle h1 {
    font-size: 55px;
  }

  .fixtureBlock {
    padding: 0 15px;
  }
  footer {
    padding-top: 5%;
  }
}
